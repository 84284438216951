import React, { useEffect } from 'react';
import './App.css';
import { Button, Modal, Spinner } from 'react-bootstrap';
import GooglePayButton from '@google-pay/button-react';
import { ApplePayButton } from 'react-apple-pay-button';
import { createGlobalState } from 'react-hooks-global-state';
import i18n from './i18n';
import { useTranslation } from "react-i18next";

// import { useHistory, BrowserRouter as Router, Route } from "react-router-dom";

const { useGlobalState } = createGlobalState({
  qr: '',
  vm: null,
  order: null,
  timer: 90
})

const App = () => {

  const { t } = useTranslation()

  const parsedUrl = new URL(window.location.href)
  const searchQr = parsedUrl.searchParams.get('qr')
  const srcNfc = parsedUrl.searchParams.get('nfc')
  const srcOrder = parsedUrl.searchParams.get('oid')

  const [order, setOrder] = useGlobalState('order')
  const [vm, setVm] = useGlobalState('vm')
  const [headerColor, setHeaderColor] = React.useState('#2843a7')
  const [headerImage, setHeaderImage] = React.useState('logo.png')
  const [timer, setTimer] = useGlobalState('timer')

  const [error, setError] = React.useState()

  const [showConnecting, setShowConnecting] = React.useState(false)
  const [showSnackScreen, setShowSnackScreen] = React.useState(false)
  const [showWaterScreen, setShowWaterScreen] = React.useState(false)
  const [showSelectPrice, setShowSelectPrice] = React.useState(false)
  const [showInitScreen, setShowInitScreen] = React.useState(!parsedUrl.searchParams.has("r"))
  const [showPaymentScreen, setShowPaymentScreen] = React.useState(false)
  const [showProcessingPayment, setShowProcessingPayment] = React.useState(false)
  const [showApplepayButton, setShowApplepayButton] = React.useState(false)
  const [showPaymentCT5, setShowPaymentCT5] = React.useState(false)
  const [showPaymentType10, setShowPaymentType10] = React.useState(false)
  const [showLangugePop, setShowLangugePop] = React.useState(false)

  const [showPaymentButton, setShowPaymentButton] = React.useState(false)
  const [amount, setAmount] = React.useState("")

  
  i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})

  function checkQr(qr) {
    fetch(`api/check?qr=${qr}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json()
      }).then((result) => {
        i18n.changeLanguage(result.language_code)
        if ((result.allowed != true && result.type_id != 10) || result.type_id == 13) {
          showNotSupportedComponent(result)
        } else if (result.status_connected == 0 && result.ct_type == 5) {
          showNotConnected(result)
        } else {
          if (result.webQRcss) {
            try {
              const webQRcss = JSON.parse(result.webQRcss)
              if (webQRcss.color) {
                setHeaderColor(webQRcss.color)
              }
              if (webQRcss.image) {
                setHeaderImage(webQRcss.image)
              }
            } catch (e) {
              console.warn("Failed to parse css", e)
            }
          }
          setVm(result)
          openOrder(result)
          setShowInitScreen(false)
          setShowConnecting(true)
        }
      }).catch(() => {
        setError({ qrValidation: t("Invalid code") })
        setShowInitScreen(true)
      })
  }

  function openOrder(vm) {
    fetch(`api/connect?qr=${vm.qr}&src=${srcNfc ? "nfc" : ""}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json()
      }).then((result) => {
        setOrder(result)
        if (vm.type_id == 10) {
          paymentType10(result, vm);
        } else if (vm.ct_type == 5) {
          preauthorizePrice(vm)
        } else {
          startCheckingOrder(result, vm)
        }
      }).catch(() => {
        setError({ qrValidation: t("Invalid code") })
      })
  }

  function startCheckingOrder(order, vm) {
    fetch(`api/order?id=${order.id}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json()
      }).then((result) => {
        checkOrderStatus(result, vm)
        if (result.device_status == null || result.device_status == 5) {
          setTimeout(() => startCheckingOrder(result, vm), 500)
        }
      }).catch(() => {
        setError({ qrValidation: t("Invalid code") })
      })
  }

  function initApplePayment(vm) {
    if (window.ApplePaySession && vm?.payment_provider_id != 2) {
      setShowApplepayButton(true)
      var promise = window.ApplePaySession.canMakePaymentsWithActiveCard('merchant.net.prostopay');
      promise.then((canMakePayments) => {
        //setShowApplepayButton(canMakePayments)
      });
    } else {
      setShowApplepayButton(false)
    }
  }

  function preauthorizePrice(vm) {
    setShowInitScreen(false)
    setShowConnecting(false)
    setShowPaymentCT5(true)
    initApplePayment(vm)
  }

  function paymentType10(order, vm) {
    setShowInitScreen(false)
    setShowConnecting(false)
    setShowPaymentType10(true)
    initApplePayment(vm)
  }

  function processingPayment() {
    setShowProcessingPayment(true);
    setShowInitScreen(false);
    setShowConnecting(false);
    setShowSnackScreen(false);
    setShowWaterScreen(false);
    setShowPaymentScreen(false);
    setShowSelectPrice(false);
    setShowPaymentCT5(false);
    setShowPaymentType10(false);
  }

  function checkOrderStatus(order, vm) {
    switch (order.device_status) {
      case 3:
        switch (order.status) {
          case 0:
          case 1:
            if (vm && vm.ct_type == 5) {
              processingPayment()
              setTimeout(() => startCheckingOrder(order, vm), 500)
            } else {
              setOrder(order)
              setTimer(-1)
              showPaymentOption()
            }
            break
          case 2:
            paymentFailed(vm)
            break
          case 3:
            setTimeout(() => startCheckingOrder(order, vm), 500)
            break
        }
        break
      case 4:
        switch (order.status) {
          case 0:
          case 1:
          case 5:
          case 2:
            paymentFailed(vm)
            break
          case 3:
            paymentCompleted(vm, order)
            break
          case 4:
            paymentRefunded(vm)
            break
        }
        break
      case 5:
        if (vm && vm.type_id == 11) {
          showWaterPage()
        } else if (vm && vm.type_id == 12) {
          showSnackPage()
        } else if (vm && vm.ct_type == 5) {
          showSelectProductCT5Page()
          setTimeout(() => startCheckingOrder(order, vm), 500)
        } else {
          showSelectPricePage()
        }
        break
      case 6:
        switch (order.status) {
          case 0:
          case 1:
          case 2:
          case 5:
            paymentFailed(vm)
            break;
          case 3:
            paymentCompleted(vm, order)
            break;
          case 4:
            paymentRefunded(vm)
            break;
        }
        break
      default:
        if (vm && vm.ct_type == 5) {
          switch (order.status) {
            case 2:
              paymentFailed(vm)
            default:
              setTimeout(() => startCheckingOrder(order, vm), 500)
          }
        }
    }
  }

  function showSelectProductCT5Page() {
    if (showSelectPrice) {
      return
    }
    setShowPaymentCT5(false)
    setShowProcessingPayment(false)
    setShowSelectPrice(true)
  }

  function showSelectPricePage() {
    if (showSelectPrice) {
      return
    }
    setShowConnecting(false)
    setShowSelectPrice(true)
  }

  function showWaterPage() {
    if (showWaterScreen) {
      return
    }
    setShowConnecting(false)
    setShowWaterScreen(true)
    initApplePayment(vm)
  }

  function showSnackPage() {
    if (showSnackScreen) {
      return
    }
    setShowConnecting(false)
    setShowSnackScreen(true)
    initApplePayment(vm)
  }

  function showPaymentOption() {
    if (showPaymentScreen) {
      return
    }
    setShowConnecting(false)
    setShowSelectPrice(false)
    setShowPaymentScreen(true)
    initApplePayment(vm)
  }

  function showNotSupportedComponent(vm) {
    window.location.href = `/?notsupported&r=${vm.qr}`
  }

  function showNotConnected(vm) {
    window.location.href = `/?notconnected&r=${vm.qr}`
  }

  function paymentFailed(vm) {
    window.location.href = `/?failed&r=${vm.qr}`
  }

  function paymentRefunded(vm) {
    window.location.href = `/?refund&r=${vm.qr}`
  }

  function paymentCompleted(vm, order) {
    if (order?.receipt_key) {
      window.location.href = `/?success&r=${vm.qr}&k=${encodeURIComponent(order.receipt_key)}`
    } else {
      window.location.href = `/?success&r=${vm.qr}`
    }
  }

  function cancelSession(order) {
    fetch(`api/cancel?id=${order?.id}`)
      .then(() => {
        window.location.href = "/"
      })
      .catch(() => {
        window.location.href = "/"
      })
  }

  function retry(qr) {
    if (qr) {
      window.location.href = `/?qr=${qr}`
    } else {
      window.location.href = `/`
    }
  }

  function fiscal(key) {
    if (key) {
      window.open(`/api/receipt?k=${key}`, '_blank');
    }
  }

  const InitComponent = () => {

    const [qr, setQr] = useGlobalState('qr')

    useEffect(() => {
      console.log(`srcOrder: ${srcOrder}`)
      if (srcOrder && !order) {
        setShowInitScreen(false)
        processingPayment(true)
        fetch(`api/order?id=${srcOrder}`)
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json()
          }).then((order) => {
            setOrder(order)
            fetch(`api/check?qr=${order.qr}`)
              .then((response) => {
                if (!response.ok) {
                  throw Error(response.statusText);
                }
                return response.json()
              }).then((vm) => {
                setVm(vm)
                checkOrderStatus(order, vm)
                if (vm.ct_type == 5) {
                  setShowPaymentCT5(false)
                }
              })
          })
      } else if (searchQr && searchQr != qr) {
        setQr(searchQr)
        checkQr(searchQr)
      }
    })

    function handleChange(e) {
      setQr(e.target.value)
    }

    function handleClick(event) {
      if (qr) {
        checkQr(qr)
      } else {
        setError({ qrValidation: t("Enter code") })
      }
      event.preventDefault();
    }

    return <>
      <div className='text'>
        {t('Scan QR')}
      </div>
      <input className="val" type="text" inputMode="numeric" placeholder={t('Enter QR')} onChange={handleChange} inputMode="numeric" value={qr} />
      <div className='text2'>
        {error?.qrValidation}
      </div>
      <Button className="main_button" type="button" variant="outline-secondary" onClick={handleClick}>
        <p className="button_text">{t('OK')}</p>
      </Button>
    </>
  }

  const PaymentComponent = () => {
    // const [vm, setVm] = useGlobalState('vm')

    return <>
      <img class="card" src="/card.svg"></img>
      <div className='text'>
        <span>{t('Order payment')}</span>
      </div>
      
      {vm?.campaign?.message &&
        <div className="promotext"><span>{vm?.campaign?.message}</span></div>
      }

      <div className="price"><span id="price" >
        {order?.amount / 100}</span> {t(`Currency.${vm?.currency_code}`)}
      </div>

      {paymentOptions(vm, order, (result) => {
          setOrder(result)
          checkOrderStatus(result, vm)
      })}

      <TimerBar />

    </>
  }

  const SnackScreenComponent = () => {
   
    function setOrderAmount(value) {
      if (value > 0 && value <= vm?.max_credit_app) {
        const ivalue = parseFloat(value.replace(",", ".")).toFixed(2)
        order.amount = Math.round(ivalue * 100)
        if (vm.campaign) {
          order.amount = Math.round(order.amount * (100 - vm.campaign.discount_percentage) / 100)
        }
        setOrder(order)
        setAmount(value)
        fetch(`api/order-amount?id=${order.id}`,
          {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'amount': order.amount })
          })
        setShowPaymentButton(true)
      } else {
        setAmount(value < vm?.max_credit_app ? value : "")
        order.amount = 0
        setOrder(order)
        setShowPaymentButton(false)
      }
    }

    return <>
      <div className="text">
        <span>{ t('Enter exact ammount') }</span>
      </div>

      <div className="div_price"><span>{t('Enter exact description')}</span></div>

      {/* <div className="water_div" style={{ marginTop: "9vh" }} >
        {[10, 15, 20, 30].map((number) =>
          <div onClick={() => { setOrderAmount(number) }} className={`water ${amount == number ? "water-selected" : ""}`}>
            <span>{number} {t(`Currency.${vm?.currency_code}`)}</span>
          </div>
        )}
      </div> */}
      
      <input className="val"
        style={{"marginTop":"9vh"}}
        type="text" inputMode="decimal" min="0.1" max="500" onChange={event => { setOrderAmount(event.target.value) }} step="1" placeholder={t('Amount')} value={amount} />
      
      <div className="pay_text">{t('Order payment')}</div>

      {vm?.campaign?.message &&
        <div className="promotext"><span>{vm?.campaign?.message}</span></div>
      }

      <div className="price"> <span id="price" >{(order?.amount / 100).toFixed(2)}</span> {t(`Currency.${vm?.currency_code??"UAH"}`)}</div>

      {showPaymentButton && paymentOptions(vm, order, (result) => {
        setOrder(result)
        checkOrderStatus(result, vm)
      })}
        
      <TimerBar />
    </>
  }

  const WaterScreenComponent = () => {
    
    function setLitre(value) {
      if (value > 0 && value <= 100) {
        order.amount = Math.round(value * 100 * vm.planogram[0].price)
        setOrder(order)
        setAmount(value)
        fetch(`api/order-amount?id=${order.id}`,
          {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'amount': order.amount })
          })
        setShowPaymentButton(true)
      } else {
        setAmount(value < vm?.max_credit_app ? value : "")
        order.amount = 0
        setOrder(order)
        setShowPaymentButton(false)
      }
    }

    return <>
      <div className="text">
        {t('Select water')}
      </div>
      <div className="water_div">
        {[5, 6, 10, 12, 19].map((number) =>
          <div onClick={() => { setLitre(number) }} className={`water ${amount == number ? "water-selected" : ""}`}>
            <span>{number} {t('Litre symbol')}</span>
          </div>
        )}
      </div>

      <input className="val water_div_val"
        style={{ "marginTop": "9vh" }}
        type="number" inputMode="decimal" pattern="[0-9]*" min="0.1" max="500" onChange={event => { setLitre(event.target.value) }} step="1" placeholder={t('Volume other')} value={amount} />

      {vm?.campaign?.message &&
        <div className="promotext"><span>{vm?.campaign?.message}</span></div>
      }
      <div className="price"><span id="price">{order?.amount / 100}</span> {t(`Currency.${vm?.currency_code}`)}</div>

      <div className="pay_text">{t('Order payment')}</div>

      {showPaymentButton && paymentOptions(vm, order, (result) => {
        setOrder(result)
        checkOrderStatus(result, vm)
      })}
        
      <TimerBar />
    </>
  }

  const TimerBar = () => {
    const [timer, setTimer] = useGlobalState('timer')
    const [order, setOrder] = useGlobalState('order')
    const [vm, setVm] = useGlobalState('vm')

    useEffect(() => {
      if (timer == -1) {
        setTimer(vm.timeout)
      } else if (timer == 0) {
        cancelSession(order)
      } else {
        const timercallback = setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);
        return () => {
          clearTimeout(timercallback);
        }
      }
    });

    return <>
      <Button onClick={() => { cancelSession(order) }} className="cancel_button">
        <p className="button_text">{t('Cancel')}</p>
      </Button>
      <span id="timer" className="timer">
        {timer}
      </span>
    </>
  }

  const ConnectingComponent = () => {
    return <>
      <div className='content text'>
        <span>
          {t('Connecting to vm')} {vm?.name} {vm?.vmNumber}
        </span>
      </div>
      <object id="vm" className="vm" data="vm.html"></object>
      <TimerBar />
    </>
  }

  const CompletedComponent = () => {
    return <>
      <div className="content text">
        <span>{t('Thank you')}</span>
      </div>

      <object className="tick" data="tick.html"
        style={{ "marginTop": "-2vmax" }}
      />

      <div className="text2">
        <span>{t('Repeat_full')}</span>
      </div>

      <Button className='cancel_button' style={{ 'top':'69.5%' }} onClick={() => { retry(parsedUrl.searchParams.get('r')) }}>
        <p className="button_text">{t('Repeat_purchase')}</p>
      </Button>

      {parsedUrl.searchParams.get('k')
        && <Button className='cancel_button' onClick={() => { fiscal(parsedUrl.searchParams.get('k')) }}>
          <p className="button_text">{t('Get fiscal receipt')}</p>
        </Button>
      }

    </>
  }

  const FailedComponent = () => {
    return <>

      <div className="content text">
        <span>{t('Failed sale')}</span>
      </div>

      <object className="tick" data="cross.html"
        style={{"marginTop":"-2vmax"}}
      />

      <div className="text2">
        <span>{t('Repeat_full')}</span>
      </div>

      <Button className='cancel_button' onClick={() => { retry(parsedUrl.searchParams.get('r')) }}><p className="button_text">{t('Repeat_purchase')}</p></Button>

    </>
  }

  const RefundedComponent = () => {
    return <>
      <object className="tick" data="cross.html"></object>

      <div className="content text">
        <span>{t('Failed Return')}</span>
      </div>
      <div className="text2">
        <span>{t('Repeat_full')}</span>
      </div>

      <Button className='cancel_button' onClick={() => { retry(parsedUrl.searchParams.get('r')) }}>
        <p className="button_text">{t('Repeat_purchase')}</p>
      </Button>

    </>
  }

  const SelectProductComponent = () => {
    return <>
      <div className="content text">
        {t('Select product')}
      </div>
      <object className="food" data="food_choice.html"></object>
      <TimerBar />
    </>
  }

  const ShowPaymentCT5Component = () => {
   
    function setOrderAmount(value) {
      const ivalue = parseFloat(value.replace(",", ".")).toFixed(2)
      if (ivalue > 0 && ivalue <= vm.max_credit_app) {
        order.amount = Math.round(ivalue * 100)
        if (vm.campaign) {
          order.amount = Math.round(order.amount * (100 - vm.campaign.discount_percentage) / 100)
        }
        setOrder(order)
        setAmount(value)
        fetch(`api/order-amount?id=${order.id}`,
          {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'amount': order.amount })
          })
        setShowPaymentButton(true)
      } else {
        setAmount(ivalue < vm?.max_credit_app ? value : "")
        order.amount = 0
        setOrder(order)
        setShowPaymentButton(false)
      }
    }

    return <>
      <div className='text'>
        <span>{t('Enter exact ammount')}</span>
      </div>
      <div className="div_price">
          <span>{t('Enter exact description')}</span>
      </div >

      {vm?.campaign?.message &&
        <div className='promotext'><span>{vm?.campaign?.message}</span></div>
      }
      
      <input
        style={{ "marginTop": "9vh" }}
        className="val" type="text" inputMode="decimal" min="0.1" max="500" onChange={event => { setOrderAmount(event.target.value) }} step="1" placeholder={t('Amount in currency', { currency: t(`Currency.${vm?.currency_code}`) })} value={amount} />

      <div className='price' >
        <span id='price'>
          {(order?.amount / 100).toFixed(2)} {t(`Currency.${vm?.currency_code}`)}
        </span>
      </div>

      <div className="pay_text">{t('Order payment')}</div>

      {showPaymentButton && paymentOptions(vm, order, (result) => {
        setShowPaymentCT5(false)
        setOrder(result)
        checkOrderStatus(result, vm)
      })}
        
    </>
  }

  const ShowPaymentType10Component = () => {
    
    function setOrderAmount(value) {
      const ivalue = parseFloat(value.replace(",", ".")).toFixed(2)
      if (ivalue > 0 && ivalue <= vm?.max_credit_app) {
        order.amount = Math.round(ivalue * 100)
        if (vm.campaign) {
          order.amount = Math.round(order.amount * (100 - vm.campaign.discount_percentage) / 100)
        }
        setOrder(order)
        setAmount(value)
        fetch(`api/order-amount?id=${order.id}`,
          {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'amount': order.amount })
          })
        setShowPaymentButton(true)
      } else {
        setAmount(ivalue < vm?.max_credit_app ? value : "")
        order.amount = 0
        setOrder(order)
        setShowPaymentButton(false)
      }
    }

    return <>
      <div className='content'>
        <div className='text'>{t('Enter amount')}</div>
        <div className="form-group form-group-sm d-flex justify-content-center">
          <input className="val" type="text" inputMode="decimal" min="0.1" max="500" onChange={event => { setOrderAmount(event.target.value) }} step="1" placeholder={t('Amount in currency', { currency: t(`Currency.${vm?.currency_code}`) })} value={amount} />
        </div>
      </div>
      <div className='pay_text'>{t('Order payment')}</div>
      {vm?.campaign?.message &&
        <div className="promotext"><span>{vm?.campaign?.message}</span></div>
      }
      <div className="price"> <span id="price" >
        {(order?.amount / 100).toFixed(2)}</span> {t(`Currency.${vm?.currency_code}`)}
      </div>

      {showPaymentButton && paymentOptions(vm, order, (result) => { paymentCompleted(vm, result); })}
    </>
  }

  const paymentOptions = (vm, order, paymentCompleted) => {

    function processGooglePayment(payload) {
      fetch(`api/paymentGoogle?id=${order?.id}`,
        {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json()
        }).then((result) => {
          paymentCompleted(result);
        }).catch(() => {
          setError({ qrValidation: t('Invalid code') })
        })
      processingPayment(true)
    }

    function onRequestApplePay() {
      var paymentRequest = {
        currencyCode: vm?.currency_code,
        countryCode: 'UA',
        total: {
          label: "Продукт",
          amount: order?.amount / 100
        },
        merchantCapabilities: ['supports3DS'],
        supportedNetworks: ['masterCard', 'visa']
      };

      var session = new window.ApplePaySession(3, paymentRequest);

      session.onvalidatemerchant = function (event) {
        validateMerchant(event.validationURL).then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        });
      }
      session.onpaymentauthorized = (appglePayment) => {
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        processingPayment(true)
        fetch(`api/paymentApple?id=${order?.id}`,
          {
            method: 'post',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(appglePayment.payment.token)
          })
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json()
          }).then((result) => {
            paymentCompleted(result);
          }).catch(() => {
            setError({ qrValidation: t('Invalid code') })
          })
      }
      session.begin();

    }

    function validateMerchant(validationURL) {
      return fetch(`api/appleValidateMerchant?url=${encodeURIComponent(validationURL)}`)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json()
        })
    }

    function onRequestTbcPayment() {
      fetch(`api/paymentTbcStart?id=${order?.id}`,
        {
          method: 'get',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response?.json()
        })
        .then((response) => {
          if (response.uri) {
            window.location.href = response.uri;
          } else {
            paymentFailed(vm)
          }
        }).catch(() => {
          setError({ qrValidation: t('Invalid code') })
        })
      // setShowPaymentScreen(false)
      // setShowPaymentCT5(false)
      // setShowProcessingPayment(true)
    }

    return <>
      {vm?.payment_provider_id == 2 && 
        <button class="pay_button tbc-checkout-pay" id="tbcCheckoutButton" onClick={onRequestTbcPayment}>
          <div class="tbc-checkout-button-logo">
            <img height="24" src="https://ecom.tbcpayments.ge/tbccheckoutbutton/tbc-logo-white.svg" />
            <span>{t(`Pay`)}</span>
          </div>
          <div class="tbc-checkout-card-icons">
            <img src="https://ecom.tbcpayments.ge/tbccheckoutbutton/visa.svg" />
            <img src="https://ecom.tbcpayments.ge/tbccheckoutbutton/mc.svg" />
            <img src="https://ecom.tbcpayments.ge/tbccheckoutbutton/QR.svg" />
          </div>
        </button>
      }
      {!showApplepayButton && vm?.payment_provider_id != 2 &&
        <div className='pay_button'>
          <GooglePayButton
            className='button_text'
            buttonType="plain"
            environment="PRODUCTION"
            paymentRequest={{
              apiVersion: 2,
              apiVersionMinor: 0,
              allowedPaymentMethods: [
                {
                  type: 'CARD',
                  parameters: {
                    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                    allowedCardNetworks: ['MASTERCARD', 'VISA'],
                  },
                  tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                      gateway: 'portmonecom',
                      gatewayMerchantId: 'BCR2DN6T7P3NR6T6',
                    },
                  },
                },
              ],
              merchantInfo: {
                merchantId: 'BCR2DN6T7P3NR6T6',
                merchantName: 'Prostopay LLC',
              },
              transactionInfo: {
                totalPriceStatus: 'FINAL',
                totalPriceLabel: 'Total',
                totalPrice: `${order?.amount / 100}`,
                currencyCode: vm?.currency_code,
                countryCode: 'UA',
              },
            }}
            onLoadPaymentData={paymentRequest => {
              processGooglePayment(paymentRequest)
            }}
            />
        </div>
      }

      {showApplepayButton && vm?.payment_provider_id != 2 &&
        <div className='pay_button'>
          <ApplePayButton onClick={onRequestApplePay} theme="dark" show={showApplepayButton} />
        </div>
      }
    </>
  }

  const NotSupportedComponent = () => {
    return <>
      <object className="quark" data="quark.html"></object>
      <div className="content text">
        <span>{t('Payment not supported')}</span>
      </div>
      <div className="text2" style={{"marginTop":"-1.7vh"}}>
        <span>{t('Payment not supported.description_1')}</span>
      </div>
    </>
  }

  const NotConnectedComponent = () => {
    return <>
      <object className="quark" data="quark.html"></object>
      <div className="content text">
        <span>{t('Try again')}</span>
      </div>
      <div className="text2">
        <span>{t('Repeat_full')}</span>
      </div>
      <Button className='cancel_button' onClick={() => { retry(parsedUrl.searchParams.get('r')) }}>
        <p className="button_text">{t('Repeat_purchase')}</p>
      </Button>
    </>
  }

  const FooterComponent = () => {
    return <>
      <div className="bottom_text">
        <a href="https://prostopay.net/" target="_blank" rel="noopener noreferrer">
          <span>{t("Copyright")}</span>
        </a>
      </div>

      <div className="agreement_text">
        <a href="https://prostopay.net/docs/privacy.html" target="_blank" rel="noopener noreferrer">
          <span>{t('Terms_of_use')}</span>
        </a>
      </div>

      {showLangugePop &&
        <ul class="language_pop">
        {["uk", "en", "ka"].map(l => {
          return <li
            className={ l==i18n.language ? "selected" : "" }
            onClick={() => {
            i18n.changeLanguage(l)
            setShowLangugePop(false)
            }}>
            {t(`Lanuage.${l.toUpperCase()}`)}
          </li>
        })}
      </ul>
      }
      <div class="language_opt">
        <a onClick={() => { setShowLangugePop(!showLangugePop) }}><img height={"30px"} src='/globe.png' /></a>
      </div>
    </>
  }
  
  
  return (
    <>
      <div>
        <img src="/gradient_up.svg" className="gradient_up" />
        <img src="/solid_up.svg" className="solid_up" />
        <img src="/logo_up.svg" className="logo_up" />
      </div>

      <div>
        <img src="/gradient_bottom.svg" className="gradient_bottom" />
        <img src="/solid_bottom.svg" className="solid_bottom" />
        <a href="https://prostopay.net/" target="_blank" rel="noopener noreferrer"><img src="/logo_bottom.svg" className="logo_bottom" /></a>
      </div>

      {showInitScreen && <InitComponent />}

      {showConnecting && ConnectingComponent()}

      {showSnackScreen && SnackScreenComponent()}

      {showWaterScreen && WaterScreenComponent()}

      {showPaymentScreen && PaymentComponent()}

      {showSelectPrice && SelectProductComponent()}

      {showPaymentCT5 && ShowPaymentCT5Component()}

      {showPaymentType10 && ShowPaymentType10Component()}

      {parsedUrl.searchParams.has('success') && CompletedComponent()}

      {parsedUrl.searchParams.has('failed') && FailedComponent()}

      {parsedUrl.searchParams.has('refund') && RefundedComponent()} 

      {parsedUrl.searchParams.has('notsupported') && NotSupportedComponent()}

      {parsedUrl.searchParams.has('notconnected') && NotConnectedComponent()}

      {FooterComponent()}

      {showProcessingPayment && <>
        <div className='content text'>
            <span>{t('Wait')}</span>
          </div>
          <object id="vm" className="vm" data="vm.html"></object>
      </>}
    </>

  )

}


export default App;
